




































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import PlusIcon from '@/app/ui/assets/ics_f_plus_circle_white.vue'
import Button from '@/app/ui/components/Button/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Draggable from 'vuedraggable'
import DragIcon from '@/app/ui/assets/drag_alt.vue'
import { IOptions } from '@/data/infrastructures/misc/interfaces/rewardAndPunishment'
import controller from '@/app/ui/controllers/RewardAndPunishmentController'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import {
  ProgramDetail,
  ProgramLevel,
} from '@/domain/entities/RewardAndPunishment'
import { Utils } from '@/app/infrastructures/misc'
import DetailSummary from '../../components/DetailSummary/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import TaskHistoryIcon from '@/app/ui/assets/ics_f_task_history_white.vue'
import { MoveProgramLevelRequest } from '@/data/payload/api/RewardAndPunishmentRequest'
import LevelTextInput from '../../components/LevelTextInput/index.vue'
import ModalConfirm from '../../components/Modals/ModalConfirm/index.vue'
import ModalSuccess from '../../components/Modals/ModalSuccess/index.vue'

@Component({
  components: {
    Button,
    DropdownSelect,
    DataTableV2,
    PaginationNav,
    Draggable,
    LoadingOverlay,
    DetailSummary,
    EmptyState,
    PlusIcon,
    DragIcon,
    TaskHistoryIcon,
    LevelTextInput,
    ModalConfirm,
    ModalSuccess,
  },
})
export default class RewardAndPunishmentProgramDetail extends Vue {
  controller = controller

  perPageOptions: IOptions[] = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]

  parameters = {
    page: 1,
    perPage: 10,
    search: '',
  }

  detailData: ProgramDetail = new ProgramDetail()

  listData: ProgramLevel[] = []

  isDeleteModalVisible = false
  isSuccessDeleteModalVisible = false
  deletedLevelId: number | undefined = undefined

  created(): void {
    this.fetchProgramDetail(true)
  }

  get params(): Record<string, string | number> {
    return {
      page: this.parameters.page,
      perPage: this.parameters.perPage,
      timezone: encodeURIComponent('+00:00'),
      keyword: this.parameters.search,
      lang: 'ID',
    }
  }

  get disableDrag(): boolean {
    return this.detailData.status !== 'Menunggu'
  }

  get validateSearch(): boolean {
    return (
      this.parameters.search.length === 0 ||
      (this.parameters.search.length > 2 &&
        this.parameters.search.length <= 100)
    )
  }

  public fetchProgramDetail(reset = false): void {
    if (reset) {
      this.parameters.page = 1
    }

    const programId = <string>this.$route.params.programId

    const payload = {
      programId: parseInt(programId),
      params: this.params,
    }

    this.controller.getProgramDetail(payload)
  }

  public onChangePerPage(perPage: number): void {
    this.parameters.perPage = perPage
    this.fetchProgramDetail(true)
  }

  public onSearch: (keyword: string) => void = Utils.debounce(
    (keyword: string) => {
      if (
        keyword.length === 0 ||
        (keyword.length > 2 && keyword.length <= 100)
      ) {
        this.fetchProgramDetail(true)
      }
    },
    500
  )

  public onChangeDrag(e: {
    moved: { newIndex: number; element: { levelId: number } }
  }): void {
    const payload = {
      programId: parseInt(<string>this.$route.params.programId),
      levelId: e.moved.element.levelId,
      newPosition:
        e.moved.newIndex +
        1 +
        this.parameters.perPage * (this.parameters.page - 1),
    }

    this.controller.moveProgramLevel(
      new MoveProgramLevelRequest(
        payload.programId,
        payload.levelId,
        payload.newPosition
      )
    )
  }

  public onDeleteLevel(): void {
    this.isDeleteModalVisible = false

    const programId = <string>this.$route.params.programId

    const payload = {
      programId: parseInt(programId),
      levelId: <number>this.deletedLevelId,
    }

    this.controller.deleteProgramLevel(payload)
  }

  @Watch('controller.programDetailData')
  onProgramDetailDataChanged(data: ProgramDetail): void {
    this.detailData = data

    this.listData =
      data.levelData?.data?.map(level => {
        return {
          ...level,
          createdAt: level.createdAt
            ? Utils.formatDateWithIDLocale(
                <string>level.createdAt + Utils.forceTimeZoneAsiaJakarta(),
                'DD MMM YYYY HH:mm'
              ) + ' WIB'
            : '-',
          updatedAt: level.updatedAt
            ? Utils.formatDateWithIDLocale(
                <string>level.updatedAt + Utils.forceTimeZoneAsiaJakarta(),
                'DD MMM YYYY HH:mm'
              ) + ' WIB'
            : '-',
        }
      }) || []
  }

  @Watch('controller.isDeleteLevelSuccess')
  onIsDeleteLevelSuccessChanged(data: boolean): void {
    if (data) {
      this.isSuccessDeleteModalVisible = true
      this.fetchProgramDetail(true)
      this.controller.setIsDeleteLevelSuccess(false)
    }
  }
}
