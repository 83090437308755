import { Utils } from '@/app/infrastructures/misc'
import {
  BonusRequestInterface,
  CourierRatingRequestInterface,
  CreateProgramLevelBonusRewardAndPunishmentRequestInterface,
  CreateProgramRewardAndPunishmentRequestInterface,
  MoveProgramLevelRequestInterface,
  RejectIgnoreSuccessPickupRequestInterface,
  UpdateProgramRewardAndPunishmentRequestInterface,
} from '../contracts/RewardAndPunishmentRequest'
import {
  CourierRating,
  ProgramLevelBonus,
  ProgramLevelPenalty,
  RejectIgnoreSuccessPickup,
} from '@/domain/entities/RewardAndPunishment'

export class CreateProgramRewardAndPunishmentRequest
  implements CreateProgramRewardAndPunishmentRequestInterface {
  public programName: string
  public periodeStart: Date
  public periodeEnd: Date
  public evaluationSchedule: string

  constructor(
    programName: string,
    periodeStart: Date,
    periodeEnd: Date,
    evaluationSchedule: string
  ) {
    this.programName = programName
    this.periodeStart = periodeStart
    this.periodeEnd = periodeEnd
    this.evaluationSchedule = evaluationSchedule
  }

  toPayload(): string {
    const payload = {
      program_name: this.programName,
      periode_start: Utils.formatDateWithIDLocale(
        this.periodeStart.toISOString(),
        'YYYY-MM-DD'
      ),
      periode_end: Utils.formatDateWithIDLocale(
        this.periodeEnd.toISOString(),
        'YYYY-MM-DD'
      ),
      evaluation_schedule: this.evaluationSchedule,
      time_zone: '+07:00',
    }

    return JSON.stringify(payload)
  }
}

export class UpdateProgramRewardAndPunishmentRequest
  implements UpdateProgramRewardAndPunishmentRequestInterface {
  public id: number
  public programName: string
  public periodeStart: Date
  public periodeEnd: Date
  public evaluationSchedule: string

  constructor(
    id: number,
    programName: string,
    periodeStart: Date,
    periodeEnd: Date,
    evaluationSchedule: string
  ) {
    this.id = id
    this.programName = programName
    this.periodeStart = periodeStart
    this.periodeEnd = periodeEnd
    this.evaluationSchedule = evaluationSchedule
  }

  toPayload(): string {
    const payload = {
      program_name: this.programName,
      periode_start: Utils.formatDateWithIDLocale(
        this.periodeStart.toISOString(),
        'YYYY-MM-DD'
      ),
      periode_end: Utils.formatDateWithIDLocale(
        this.periodeEnd.toISOString(),
        'YYYY-MM-DD'
      ),
      evaluation_schedule: this.evaluationSchedule,
    }

    return JSON.stringify(payload)
  }
}

export class MoveProgramLevelRequest
  implements MoveProgramLevelRequestInterface {
  public programId: number
  public programLevelId: number
  public newPosition: number

  constructor(programId: number, programLevelId: number, newPosition: number) {
    this.programId = programId
    this.programLevelId = programLevelId
    this.newPosition = newPosition
  }

  toPayload(): string {
    const payload = {
      program_id: this.programId,
      program_level_id: this.programLevelId,
      new_position: this.newPosition,
    }

    return JSON.stringify(payload)
  }
}

export class CreateProgramLevelBonusRewardAndPunishmentRequest
  implements CreateProgramLevelBonusRewardAndPunishmentRequestInterface {
  public levelName?: string
  public courierRating?: CourierRating
  public rejectIgnore?: RejectIgnoreSuccessPickup
  public successPickup?: RejectIgnoreSuccessPickup
  public bonus?: ProgramLevelBonus
  public penalty?: ProgramLevelPenalty

  constructor(
    levelName?: string,
    courierRating?: CourierRatingRequestInterface,
    rejectIgnore?: RejectIgnoreSuccessPickupRequestInterface,
    successPickup?: RejectIgnoreSuccessPickupRequestInterface,
    bonus?: ProgramLevelBonus,
    penalty?: ProgramLevelPenalty
  ) {
    this.levelName = levelName
    this.courierRating = courierRating
    this.rejectIgnore = rejectIgnore
    this.successPickup = successPickup
    this.bonus = bonus
    this.penalty = penalty
  }

  toPayload(): string {
    const payload = {
      level_name: this.levelName,
      courier_rating: {
        rate: this.courierRating?.rate,
        operator: this.courierRating?.operatorText?.toUpperCase()
      },
      reject_ignore: {
        percentage: this.rejectIgnore?.percentage,
        operator: this.rejectIgnore?.operatorText?.toUpperCase()
      },
      success_pickup: {
        percentage: this.successPickup?.percentage,
        operator: this.successPickup?.operatorText?.toUpperCase()
      },
      bonus: {
        weekday: {
          is_pickup: true,
          is_delivery: this.bonus?.weekday?.isDelivery,
          withdrawal_fee: this.bonus?.weekday?.withdrawalFee,
          data: this.bonus?.weekday?.data?.map(bonus => ({
            bonus_id: bonus.bonusId,
            is_randomize: bonus.isRandomize,
            time_pickup_minimum: bonus.timePickupMinimum,
            time_pickup_maximum: bonus.timePickupMaximum,
            bonus_fee: bonus.bonusFee?.map(fee => ({
              bonus_fee_id: fee.bonusFeeId,
              fee: fee.fee,
              quota: fee.isUnlimited ? null : fee.quota,
              is_unlimited: fee.isUnlimited,
            })),
          })),
        },
        weekend: {
          is_pickup: true,
          is_delivery: this.bonus?.weekend?.isDelivery,
          withdrawal_fee: this.bonus?.weekend?.withdrawalFee,
          data: this.bonus?.weekend?.data?.map(bonus => ({
            bonus_id: bonus.bonusId,
            is_randomize: bonus.isRandomize,
            time_pickup_minimum: bonus.timePickupMinimum,
            time_pickup_maximum: bonus.timePickupMaximum,
            bonus_fee: bonus.bonusFee?.map(fee => ({
              bonus_fee_id: fee.bonusFeeId,
              fee: fee.fee,
              quota: fee.isUnlimited ? null : fee.quota,
              is_unlimited: fee.isUnlimited,
            })),
          })),
        },
      },
      penalty: {
        is_freeze: this.penalty?.isFreeze,
        list: this.penalty?.list?.map((penalty) => {
          return {
            time_pikup_minimum: penalty.timePickupMinimum,
            time_pikup_maximum: penalty.timePickupMaximum,
            penalty_amount: penalty.penaltyAmount
          }
        })
      }
    }

    return JSON.stringify(payload)
  }
}
