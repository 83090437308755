























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ProgramDetail } from '@/domain/entities/RewardAndPunishment'
import { Utils } from '@/app/infrastructures/misc/Utils'
import Badge from '@/app/ui/components/Badge/index.vue'

@Component({
  components: {
    Badge,
  },
})
export default class DetailSummary extends Vue {
  @Prop({ type: Object }) private data!: ProgramDetail
  @Prop({ type: Boolean, default: false }) private hideHistoryLog!: boolean

  detailData: ProgramDetail = new ProgramDetail()
  status: { type: string; text: string } = {
    text: '-',
    type: 'warning',
  }

  created(): void {
    this.setDetailSummmaryData()
  }

  private setDetailSummmaryData(): void {
    this.detailData = {
      ...this.detailData,
      createdAt: this.data.createdAt
        ? Utils.formatDateWithIDLocale(
            <string>this.data.createdAt + Utils.forceTimeZoneAsiaJakarta(),
            'DD MMMM YYYY, HH:mm'
          ) + ' WIB'
        : '-',
      createdBy: this.data.createdBy || '-',
      updatedAt: this.data.updatedAt
        ? Utils.formatDateWithIDLocale(
            <string>this.data.updatedAt + Utils.forceTimeZoneAsiaJakarta(),
            'DD MMMM YYYY, HH:mm'
          ) + ' WIB'
        : '-',
      updatedBy: this.data.updatedBy || '-',
      programName: this.data.programName || '-',
      periodeStart: this.data.periodeStart
        ? Utils.formatDateWithIDLocale(
            <string>this.data.periodeStart + Utils.forceTimeZoneAsiaJakarta(),
            'DD MMM YYYY'
          )
        : '-',
      periodeEnd: this.data.periodeEnd
        ? Utils.formatDateWithIDLocale(
            <string>this.data.periodeEnd + Utils.forceTimeZoneAsiaJakarta(),
            'DD MMM YYYY'
          )
        : '-',
      evaluationSchedule: this.data.evaluationSchedule || '-',
      status: this.data.status || '-',
    }

    this.statusMapper(<string>this.data.status)
  }

  private statusMapper(status: string): void {
    switch (status) {
      case 'Menunggu':
        this.status = {
          text: status,
          type: 'warning',
        }
        break

      case 'Aktif':
        this.status = {
          text: status,
          type: 'success',
        }
        break

      default:
        this.status = {
          text: status,
          type: 'error-1',
        }
        break
    }
  }

  @Watch('data')
  onDetailDataChanged(): void {
    this.setDetailSummmaryData()
  }
}
