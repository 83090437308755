import { Utils } from '@/app/infrastructures/misc'
import { Pagination } from '@/domain/entities/Pagination'
import {
  Program,
  ProgramDetail,
  ProgramLevelDetail,
} from '@/domain/entities/RewardAndPunishment'
import { Programs } from '@/domain/entities/RewardAndPunishment'
import { container } from 'tsyringe'
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators'
import { RewardAndPunishmentPresenter } from '../presenters/RewardAndPunishmentPresenter'
import store from '../store'
import Vue from 'vue'
import {
  CreateProgramLevelBonusRewardAndPunishmentRequest,
  CreateProgramRewardAndPunishmentRequest,
  MoveProgramLevelRequest,
  UpdateProgramRewardAndPunishmentRequest,
} from '@/data/payload/api/RewardAndPunishmentRequest'

interface RewardAndPunishmentStateInterface {
  isLoading: boolean
  isLoadingSubmitProgram: boolean
  paginationData: Pagination
  programData: Program[]
  isPeriodeAlreadyUsed: boolean
  isSuccessCreateProgram: boolean
  isSuccessUpdateProgram: boolean
  isSuccessDeleteProgram: boolean
  programId: number
  isClearForm: boolean
  programDetailData: ProgramDetail
  isDeleteLevelSuccess: boolean
  programLevelDetailData: ProgramLevelDetail
  isSubmitProgramLevelSuccess: boolean
}

@Module({
  namespaced: true,
  store,
  name: 'reward-and-punishment',
  dynamic: true,
})
class RewardAndPunishmentController extends VuexModule
  implements RewardAndPunishmentStateInterface {
  private presenter: RewardAndPunishmentPresenter = container.resolve(
    RewardAndPunishmentPresenter
  )
  public isLoading = false
  public isLoadingSubmitProgram = false
  public paginationData = new Pagination()
  public programData: Program[] = []
  public isPeriodeAlreadyUsed = false
  public isSuccessCreateProgram = false
  public isSuccessUpdateProgram = false
  public isSuccessDeleteProgram = false
  public programId = NaN
  public isClearForm = false
  public programDetailData: ProgramDetail = new ProgramDetail()
  public isDeleteLevelSuccess = false
  public programLevelDetailData: ProgramLevelDetail = new ProgramLevelDetail()
  public isSubmitProgramLevelSuccess = false

  @Action({ rawError: true })
  public async getAllProgram(params: Record<string, string | number>) {
    this.setIsLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAllProgram(formattedParams)
      .then(res => {
        this.setPrograms(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Reward & Punishment Program List Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @Action({ rawError: true })
  public async createProgram(
    payload: CreateProgramRewardAndPunishmentRequest
  ): Promise<void> {
    this.setIsLoadingSubmitProgram(true)

    this.presenter
      .createProgram(payload)
      .then(id => {
        this.setProgramId(id)
        this.setIsSuccessCreateProgram(true)
      })
      .catch(error => {
        if (
          error.status === 400 &&
          error.error.message.en === 'error_active_program'
        ) {
          this.setIsPeriodeAlreadyUsed(true)
        } else {
          Vue.notify({
            title: 'Create Reward & Punishment Program Failed',
            text: [400, 422].includes(error.status)
              ? error.error.message.en
              : 'Something went wrong',
            type: 'error',
            duration: 5000,
          })
        }
      })
      .finally(() => {
        this.setIsLoadingSubmitProgram(false)
      })
  }

  @Action({ rawError: true })
  public async updateProgram(
    payload: UpdateProgramRewardAndPunishmentRequest
  ): Promise<void> {
    this.setIsLoadingSubmitProgram(true)

    this.presenter
      .updateProgram(payload)
      .then(() => {
        this.setIsSuccessUpdateProgram(true)
        Vue.notify({
          title: 'Update Reward & Punishment Program Success',
          text: 'Reward & Punishment Program Updated Successfully',
          type: 'success',
          duration: 5000,
        })
      })
      .catch(error => {
        if (
          error.status === 422 &&
          (error.error.message.en === 'error_active_program' ||
            error.error.message.en.includes('already an active'))
        ) {
          this.setIsPeriodeAlreadyUsed(true)
        }
        Vue.notify({
          title: 'Update Reward & Punishment Program Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setIsLoadingSubmitProgram(false)
      })
  }

  @Action({ rawError: true })
  public async deleteProgram(id: number): Promise<void> {
    this.setIsLoadingSubmitProgram(true)

    this.presenter
      .deleteProgram(id)
      .then(() => {
        this.setIsSuccessDeleteProgram(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Delete Reward & Punishment Program Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setIsLoadingSubmitProgram(false)
      })
  }

  @Action({ rawError: true })
  public async getProgramDetail(payload: {
    programId: number
    params: Record<string, string | number>
  }): Promise<void> {
    this.setIsLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(payload.params),
      'snake_case'
    )

    this.presenter
      .getProgramDetail(payload.programId, formattedParams)
      .then(res => {
        this.setProgramDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Reward & Punishment Program Detail Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @Action({ rawError: true })
  public async moveProgramLevel(
    payload: MoveProgramLevelRequest
  ): Promise<void> {
    this.setIsLoading(true)

    this.presenter
      .moveLevel(payload)
      .then(() => {
        Vue.notify({
          title: 'Move Reward & Punishment Program Level Success',
          text: 'Reward & Punishment Program Level Moved Successfully',
          type: 'success',
          duration: 5000,
        })
      })
      .catch(error => {
        Vue.notify({
          title: 'Move Reward & Punishment Program Level Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @Action({ rawError: true })
  public async deleteProgramLevel(params: {
    programId: number
    levelId: number
  }): Promise<void> {
    this.setIsLoading(true)

    this.presenter
      .deleteLevel(params.programId, params.levelId)
      .then(() => {
        this.setIsDeleteLevelSuccess(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Delete Reward & Punishment Program Level Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @Action({ rawError: true })
  public async getProgramLevel(params: {
    programId: number
    levelId: number
  }): Promise<void> {
    this.setIsLoading(true)

    this.presenter
      .getLevel(params.programId, params.levelId)
      .then(res => {
        this.setProgramLevelDetailData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Reward & Punishment Program Level Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @Action({ rawError: true })
  public async CreateProgramLevel(params: {
    programId: number
    payload: CreateProgramLevelBonusRewardAndPunishmentRequest
  }): Promise<void> {
    this.setIsLoading(true)

    this.presenter
      .createLevel(params.programId, params.payload)
      .then(() => {
        this.setIsSubmitProgramLevelSuccess(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Create Reward & Punishment Program Level Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @Action({ rawError: true })
  public async UpdateProgramLevel(params: {
    programId: number
    levelId: number
    payload: CreateProgramLevelBonusRewardAndPunishmentRequest
  }): Promise<void> {
    this.setIsLoading(true)

    this.presenter
      .updateLevel(params.programId, params.levelId, params.payload)
      .then(() => {
        this.setIsSubmitProgramLevelSuccess(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Reward & Punishment Program Level Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @Mutation
  private setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading
  }

  @Mutation
  private setIsLoadingSubmitProgram(isLoading: boolean): void {
    this.isLoadingSubmitProgram = isLoading
  }

  @Mutation
  private setPrograms(programs: Programs): void {
    this.paginationData = <Pagination>programs.pagination
    this.programData = <Program[]>programs.data
  }

  @Mutation
  public setIsPeriodeAlreadyUsed(bool: boolean): void {
    this.isPeriodeAlreadyUsed = bool
  }

  @Mutation
  public setIsSuccessCreateProgram(bool: boolean): void {
    this.isSuccessCreateProgram = bool
  }

  @Mutation
  public setIsSuccessUpdateProgram(bool: boolean): void {
    this.isSuccessUpdateProgram = bool
  }

  @Mutation
  public setIsSuccessDeleteProgram(bool: boolean): void {
    this.isSuccessDeleteProgram = bool
  }

  @Mutation
  public setProgramId(id: number): void {
    this.programId = id
  }

  @Mutation
  public setIsClearForm(bool: boolean): void {
    this.isClearForm = bool
  }

  @Mutation
  private setProgramDetail(data: ProgramDetail): void {
    this.programDetailData = data
    this.paginationData = <Pagination>data.levelData?.pagination
  }

  @Mutation
  public setIsDeleteLevelSuccess(bool: boolean): void {
    this.isDeleteLevelSuccess = bool
  }

  @Mutation
  private setProgramLevelDetailData(data: ProgramLevelDetail): void {
    this.programLevelDetailData = data
  }

  @Mutation
  public setIsSubmitProgramLevelSuccess(bool: boolean): void {
    this.isSubmitProgramLevelSuccess = bool
  }
}

export default getModule(RewardAndPunishmentController)
